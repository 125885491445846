import formatNumberMixin from "@/mixins/common/FormatNumberMixin";

export default {
  mixins: [formatNumberMixin],
  methods: {
    balanceBeforeDot(val) {
      return this.formatNumber(+val)
        .toString()
        .split(".")[0];
    },
    balanceAfterDot(val) {
      return (
        this.formatNumber(+val)
          .toString()
          .split(".")[1] || "00"
      );
    },
  },
};
