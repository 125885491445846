import router from "../router";

export default {
  NEW_CHARACTER_HAS_BEEN_MINTED:
    "A new character has been added to your collection",
  ADD_TO_COMMUNITY: "Your shoppie has been added to the breeding",
  REMOVE_FROM_COMMUNITY: "Your shoppie has been removed from breeding",
  BREEDING_SUCCESS: "Breeding is successful",
  CSH_IS_ON_MARKETPLACE: {
    title: "Congrats, item is on the market!",
    message: "",
    btnName: "Back to Market",
    redirectTo: `/${router.currentRoute.params?.lang || "en"}/market`,
  },
  SUCCESS_PURCHASED: {
    title: "Congrats!",
    message: "",
    btnName: "Back to Market",
    redirectTo: `/${router.currentRoute.params?.lang || "en"}/market`,
  },
  CSH_REMOVED_FROM_MARKET: {
    title: "Item removed from Market",
    message: "",
    btnName: "Back to Market",
    redirectTo: `/${router.currentRoute.params?.lang || "en"}/market`,
  },
  SOLD_TO_SYSTEM: {
    title: "Congrats, item sold to System",
    message: "",
    btnName: "Back to Market",
    redirectTo: `/${router.currentRoute.params?.lang || "en"}/market`,
  },
  OPERATION_SUCCESSFUL: {
    title: "Congrats!",
    message: "",
    btnName: "Back to Market",
    redirectTo: `/${router.currentRoute.params?.lang || "en"}/market`,
  },
};
