<template>
  <div class="upgrade-char" :class="{ 'upgrade-char--big': isBigCard }">
    <div class="upgrade-char-wrap">
      <div class="upgrade-char__img">
        <CharacterModel :image="characterImage" />
      </div>

      <div class="upgrade-char__wrap">
        <div class="upgrade-char__id">#{{ nftId }}</div>

        <div class="upgrade-char__name">{{ characterName }}</div>

        <KindBadge />
      </div>
    </div>

    <div class="upgrade-footer" v-show="isBigCard">
      <div class="upgrade-footer__title">
        {{ $t("buy-monster-price") }}
      </div>

      <div class="upgrade-footer__amount" v-if="price">
        <CoinGoldSvg />
        <p>
          SUB {{ balanceBeforeDot(price) }}.<span>{{
            balanceAfterDot(price)
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CharacterModel from "./СharacterModel";
import KindBadge from "../common/badges/KindBadge";
import CoinGoldSvg from "../icons/CoinGoldSvg";
import FormatPriceMixin from "../../mixins/common/FormatPriceMixin";
export default {
  name: "CharCard",
  mixins: [FormatPriceMixin],
  components: { CoinGoldSvg, KindBadge, CharacterModel },
  props: {
    nftId: {
      required: true,
    },
    characterName: {
      required: true,
    },
    characterImage: {
      required: true,
    },
    isBigCard: {
      default: false,
    },
    price: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
};
</script>

<style scoped></style>
