export default {
  CANT_BREED_WITH_ITSELF: {
    title: "",
    message: "Cryptoshoppie cannot breed with itself",
  },
  CARD_ALREADY_USED: {
    title: "Card already used",
    message: "Sorry this card already used, try another card",
  },
  DECODE_EXCEPTION: {
    title: "Decode exception",
    message: "Something went wrong while decoding, try again",
  },
  DEVICE_WITHOUT_CAMERA: {
    title: "Your device without camera",
    message: "Your device has no video camera",
  },
  CAMERA_PERMISSION_DENIED: {
    title: "",
    message: "Allow to use camera",
  },
  NEED_WALLET_RESTORE: {
    title: "",
    message: "You need restore your private key",
  },
  NOT_SUPPORT_NATIVE_SHARE: {
    title: "",
    message: "Your browser does not support Android Native Share",
  },
  CHARACTER_ALREADY_ADDED: {
    title: "",
    message: "You have already added this character to the game",
  },
  INSUFFICIENT_FUNDS: {
    title: "",
    message:
      "You do not have enough funds in your account to pay the commission",
  },
  NOT_ENOUGH_CHARACTERS: {
    title: "",
    message: "You don't have enough characters to start the game",
  },
  QR_CODE_NOT_DETECTED: {
    title: "",
    message: "Qr code not detected",
  },
  UNKNOWN_ERROR: {
    title: "",
    message: "Unknown error occurred",
  },
};
