<template>
  <div class="buy-monster">
    <div class="title-md">
      {{ $t("buy-monster-title") }}
    </div>

    <div class="subtitle">
      {{ $t("buy-monster-sub") }}
    </div>

    <CharCard
      :is-big-card="true"
      :character-image="characterImage"
      :character-name="characterName"
      :nft-id="nftId"
      :price="buyPrice"
    />

    <div class="buy-info">
      <div class="buy-info__title">
        {{ $t("buy-monster-rules") }}
      </div>

      <div class="buy-info__text">
        {{ $t("buy-monster-text") }}
      </div>
    </div>

    <button class="game-btn" @click="buyCharacter">
      <p>{{ $t("buy-monster-btn") }}</p>
      <p>
        SUB {{ balanceBeforeDot(buyPrice) }}.<span>{{
          balanceAfterDot(buyPrice)
        }}</span>
      </p>
    </button>

    <ErrorModal
      v-if="errorFunds"
      :funds-error="true"
      :title="$t('error-funds')"
      message="''"
    />
    <ErrorModal
      v-if="errorItem"
      :title="$t('error-item')"
      :btn-name="'back-market-btn'"
      message="''"
    />
    <SuccessModal
      v-if="successBuy"
      :title="$t('success-title')"
      message="''"
      :btn-name="$t('back-market-btn')"
    />
  </div>
</template>

<script>
import ErrorModal from "../../components/modals/ErrorModal";
import SuccessModal from "../../components/modals/SuccessModal";
import CharCard from "../../components/character/CharCard";
import LoadShoppieByIdMixin from "../../mixins/loadShoppieByIdMixin";
import FormatPriceMixin from "../../mixins/common/FormatPriceMixin";
import contract from "../../api/contract";
import errors from "../../utils/errors";
import api from "../../api/api";
import successes from "../../utils/successes";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";

export default {
  name: "BuyChar",
  mixins: [LoadShoppieByIdMixin, FormatPriceMixin, SetProcessingMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorFunds: false,
      successBuy: false,
      errorItem: false,
    };
  },
  computed: {
    buyPrice() {
      return +this.$route.query.price || 0;
    },
    buyCommission() {
      return +this.$route.query.commission || 0;
    },
    itemId() {
      return this.$route.query.itemId || "";
    },
  },
  methods: {
    async buyCharacter() {
      try {
        this.setLoading(true);
        const [error, signatures] = await contract.buyCsh(
          this.buyPrice,
          this.buyCommission,
          this.nftId
        );
        if (error) {
          this.setError({ message: error?.message || errors.UNKNOWN_ERROR });
          return;
        }

        if (signatures) {
          await api.buyCsh({
            itemId: this.itemId,
            signatures,
          });
          this.setSuccess(successes.SUCCESS_PURCHASED);
          this.loadBalance();
        }
      } catch (e) {
        this.setError({ message: e.response.data.error.message });
      } finally {
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.getCryptoshoppiesById(this.id);
  },
  components: { CharCard, SuccessModal, ErrorModal },
};
</script>

<style scoped></style>
