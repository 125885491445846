<template>
  <div class="badge badge--kind">
    <div class="badge__icon">
      <LegendarySvg />
    </div>
    <div class="badge__amount">
      {{ $t("badge-legendary") }}
    </div>
  </div>
</template>

<script>
import LegendarySvg from "../../icons/LegendarySvg";
export default {
  name: "KindBadge",
  components: { LegendarySvg },
  props: {
    title: {
      type: Number,
      //   required: true,
    },
  },
};
</script>
